<template>
  <div id="container">
    <div id="login" v-if="!isLoggedIn">
      <form @submit.prevent="login">
        <div id="username_div">
          <custom-input
            :placeholder='$gettext("Nome utente")'
            label="username"
            :value="username"
            type="text"
            @input="(val) => (username = val)"
          />
          <!--<label for="username">Username</label>
        <input type="text" v-model="username" id="username">-->
        </div>
        <div id="password_div">
          <custom-input
            placeholder="Password"
            label="password"
            :value="password"
            type="password"
            @input="(val) => (password = val)"
          />
          <!--<label for="username">password</label>
        <input type="text" v-model="password" id="password">-->
        </div>
        <div id="message" v-html="message" v-if="message != ''"></div>
        <div id="errore" v-html="error" v-if="error != ''"></div>
        <div id="newcode">
          <br />
          {{ $gettext('Password dimenticata') }}?
          <router-link :to="{ name: 'Lost password step 1' }">
            {{ $gettext('Richiedi nuova password') }}
          </router-link>
        </div>
        <div id="buttons">
          <button :disabled="!fillOk">Login</button>
        </div>
      </form>
      <hr />
      <h2>{{ $gettext('Vuoi inserire le tue ricette ?') }}</h2>
      <button @click="registrati">{{ $gettext('Registrati') }}</button>
    </div>
    <div id="logout" v-if="isLoggedIn">
      <h2>{{ $gettext('Login effettuato') }}</h2>
      <div class="container">
        {{ $gettext('Login effettuato con successo.') }}
        <br />
        <button @click="goHome">{{ $gettext('Vai alla home') }}</button>
      </div>
    </div>
  </div>
</template>

<script>
import customInput from '@/components/shared/custom-input';
export default {
  name: 'login',
  components: { customInput },
  methods: {
    async login() {
      if (this.username === 'a') {
        this.username = 'michel';
        this.password = '@kpQTgNOFV9JXieC*YlVpq)k';
      }
      if (this.username === 'm') {
        this.username = 'michel1';
        this.password = '.m1ch3l1.';
      }
      if (this.username.trim() == '' || this.password.trim() == '') return;

      this.error = '';
      this.message = 'Login in corso...';
      this.loading = true;
      try {
        let response = await this.axios.post(
          this.api.login,
          {},
          {
            params: {
              username: this.username,
              password: this.password,
            },
          },
        );
        this.$store.commit('auth/setLoggedIn', response.data);
        const token = response.data.token;

        response = await this.axios.get(this.api.getMe, {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        });

        this.$store.commit('auth/setDataProfile', response.data);
        this.$language.current = response.data.language;
        this.message = '';

        //Prendo i miei dati.
        this.$router.replace('/');
      } catch (error) {
        this.message = '';
        this.loading = false;

        switch (error.response.data.code) {
          case '[jwt_auth] invalid_username':
            this.error = $gettext('Dati inseriti non corretti.') + '<br><strong></strong>';
            break;

          case '[jwt_auth] locked':
            this.error =
              $gettext("Il tuo account è locato. Se non l'hai ancora attivato <a href='/resend_code'>clicca qui</a> ");
            break;

          default:
            this.error =
              error.response.data.code + '<br>' + error.response.data.message;
            break;
        }
      }
    },
    registrati() {
      this.$router.push('/signup');
    },
    logout() {
      this.$store.commit('auth/setLogout');
    },
    goHome() {
      this.$router.replace('/');
    },
  },
  data() {
    return {
      username: '',
      password: '',
      error: '',
      message: '',
      loading: false,
    };
  },
  computed: {
    isLoggedIn() {
      return this.$store.getters['auth/isLoggedIn'];
    },
    fillOk() {
      return (
        this.username.trim() != '' &&
        this.password.trim() != '' &&
        !this.loading
      );
    },
  },
};
</script>

<style scoped>
#buttons {
  margin-top: 20px;
  margin-bottom: 20px;
}

#errore {
  margin-top: 10px;
}

#message {
  margin-top: 10px;
}

a {
  color: var(--var-primary-color);
  font-style: italic;
  font-weight: 600;
}

#newcode {
  color: var(--var-primary-color);
  font-size: 80%;
}

#container {
  padding: 8px;
}
</style>
